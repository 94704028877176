
import React, { useState, useEffect } from "react";
import { Nav } from './Nav';
import ImageUploader from "react-images-upload";
import PostApi from '../Services/PostApi';
import FileApi from '../Services/FileApi';
import { ToastContainer, toast } from 'react-toast';
import { useNavigate } from "react-router-dom";
import { UtilsJson } from "../utils/UtilsJson";
function Forms() {
    const [step, setStep] = useState(1);
    const [, setPhoto] = useState([]);
    const [, setAdharPhoto] = useState([]);
    const [, setSchoolTC] = useState([]);
    const [, setCollegeTC] = useState([]);
    const [, setExperienceCertificate] = useState([]);
    const [, setResumeCertificate] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [percentage, setPercentage] = useState(0);
    let navigate = useNavigate();
    const [pcount, setPcount] = useState(0);
    const [eduCount, setEduCount] = useState(0);
    const [expCount, setExpCount] = useState(0);
    const [jobCount, setJobCount] = useState(0);
    var p = ["eks_form_name",
        "eks_form_father_name",
        "eks_form_dob",
        "eks_form_mobile",
        "eks_form_mail",
        "eks_form_gender",
        "eks_form_selfie",
        "eks_form_aadhar"];
    var ed = ["eks_form_qualification",
        "eks_form_sch_cmp_yr",
        "eks_form_sch_name",
        "eks_form_sch_cert",
        "eks_form_clg_name",
        "eks_form_clg_cource",
        "eks_form_clg_cert",
        "eks_form_clg_cmp_yr"];
    var ex = ["eks_form_exp",
        "eks_form_exp_details",
        "eks_form_sup_name",
        "eks_form_sup_no",
        "eks_form_exp_cert",
        "eks_form_last_salary",
        "eks_form_resume_cert"];
    var j = ["eks_form_stay",
        "eks_form_location",
        "eks_form_process",
        "eks_form_exp_salary",];

    const Photo = photo => {
        setPhoto([...photo, photo]);
        (photo.length) ? UploadImage(photo, '1') : setFormValues({ ...formValues, 'eks_form_selfie': '' });
    };

    const AdharPhoto = adharPhoto => {
        setAdharPhoto([...adharPhoto, adharPhoto]);
        (adharPhoto.length) ? UploadImage(adharPhoto, '2') : setFormValues({ ...formValues, 'eks_form_aadhar': '' });
    };

    const SchoolTC = schoolTC => {
        setSchoolTC([...schoolTC, schoolTC]);
        (schoolTC.length) ? UploadImage(schoolTC, '3') : setFormValues({ ...formValues, 'eks_form_sch_cert': '' });
    };

    const CollegeTC = collegeTC => {
        setCollegeTC([...collegeTC, collegeTC]);
        (collegeTC.length) ? UploadImage(collegeTC, '4') : setFormValues({ ...formValues, 'eks_form_clg_cert': '' });
    };

    const ExperienceCertificate = experienceCertificate => {
        setExperienceCertificate([...experienceCertificate, experienceCertificate]);
        (experienceCertificate.length) ? UploadImage(experienceCertificate, '5') : setFormValues({ ...formValues, 'eks_form_exp_cert': '' });
    }

    const ResumeCertificate = resumeCertificate => {
        setResumeCertificate([...resumeCertificate, resumeCertificate]);
        (resumeCertificate.length) ? UploadImage(resumeCertificate, '6') : setFormValues({ ...formValues, 'eks_form_resume_cert': '' });
    }

    const findPercentage = (res) => {
        if (typeof (res['eks_form_mobile']) != 'undefined') {
            var count = -4;
            var pC = 0;
            var edC = 0;
            var exC = 0;
            var jC = 0;
            for (var key in res) {
                if (res.hasOwnProperty(key)) {
                    if (res[key]) {
                        if (res[key] !== '0000-00-00')
                            count++;
                        if (p.includes(key)) {
                            if (res[key] !== '0000-00-00')
                                pC++;
                        }
                        if (ed.includes(key)) {
                            edC++;
                        }
                        if (ex.includes(key)) {
                            exC++;
                        }
                        if (j.includes(key)) {
                            jC++;
                        }
                    }
                }
            }
            setPercentage(Math.round(count / 27 * 100));
            setPcount(pC);
            setEduCount(edC);
            setExpCount(exC);
            setJobCount(jC);
        }
    }

    /* Set Values to form  */
    const handlechange = (e, type) => {
        const { name, value, checked } = e.target;
        (type === 'checkbox') ?
            setFormValues({ ...formValues, [name]: getValuess(name, value, checked).toString() })
            :
            setFormValues({ ...formValues, [name]: value });
        const res = { ...formValues, [name]: value }
        findPercentage(res);
    };

    const getValuess = (name, value, checked) => {
        const formData = formValues[name].toString().split(',');
        return checked ? formData.indexOf(value) > -1 ? formData : [...formData, value] : formData ? formData.toString().split(',').filter(item => item !== value) : [...formData, value];
    }

    const UploadImage = (e, type) => {
        FileApi(e[0]).then((result) => {
            (type === '1') ?
                setFormValues({ ...formValues, 'eks_form_selfie': result.responceFileData.data.result })
                : (type === '2') ?
                    setFormValues({ ...formValues, 'eks_form_aadhar': result.responceFileData.data.result })
                    : (type === '3') ?
                        setFormValues({ ...formValues, 'eks_form_sch_cert': result.responceFileData.data.result })
                        : (type === '4') ?
                            setFormValues({ ...formValues, 'eks_form_clg_cert': result.responceFileData.data.result })
                            : (type === '5') ?
                                setFormValues({ ...formValues, 'eks_form_exp_cert': result.responceFileData.data.result })
                                : setFormValues({ ...formValues, 'eks_form_resume_cert': result.responceFileData.data.result });
        });
    };

    const FormUpdate = (message) => {
        let tableData = { "list_key": "UpdateMaster", "label": "eks_form", "condition": { "status": 1, "eks_form_mobile": JSON.parse(sessionStorage.getItem('user'))[0].eks_form_mobile }, "update_coloum": formValues }
        PostApi('services.php', tableData).then((e) => {
            toast.success(message + ' Updated Successfully');
        });
    }

    useEffect(() => {
        if (!sessionStorage.getItem('user')) {
            navigate('/');
        }
        let tableData = { "list_key": "Mastertable", "label": "eks_form", "select": "*", "condition": { "status": 1, "eks_form_mobile": JSON.parse(sessionStorage.getItem('user'))[0].eks_form_mobile } }
        PostApi('services.php', tableData).then((e) => {
            let res = e.responcePostData.data.result[0];
            if (typeof (res.eks_form_exp_details) == 'undefined' || !res.eks_form_exp_details) {
                res['eks_form_exp_details'] = '[{ "name": "", "fDate": "", "tDate":"","dept":"" }]';
            }
            setFormValues(res);
            findPercentage(res);
        });
    }, []);

    let handleChange = (i, e) => {
        let newFormValues = JSON.parse(formValues['eks_form_exp_details']);
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues({ ...formValues, 'eks_form_exp_details': JSON.stringify(newFormValues) });
    }

    let addFormFields = () => {
        let addFormValues = JSON.parse(formValues['eks_form_exp_details']);
        addFormValues.push({ "name": "", "fDate": "", "tDate": "", "dept": "" })
        setFormValues({ ...formValues, 'eks_form_exp_details': JSON.stringify(addFormValues) });
    }

    let removeFormFields = (i) => {
        let removeFormValues = JSON.parse(formValues['eks_form_exp_details']);
        removeFormValues.splice(i, 1);
        setFormValues({ ...formValues, 'eks_form_exp_details': JSON.stringify(removeFormValues) });
    }

    const addForms = () => {
        if (typeof (formValues['eks_form_exp_details']) != 'undefined') {
            return JSON.parse(formValues['eks_form_exp_details']).map((element, index) => (
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-9 bg-slate-100 hover:bg-slate-200 p-5 mt-2" key={index}>
                    <span className="text-bold bg-yellow-400 p-2 flex align-middle justify-center items-center rounded-full">{index + 1}</span>
                    <div className="col-span-2">

                        <label className="font-bold" htmlFor="eks_form_name"> Company Name</label>
                        <input type="text" id="name" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                    </div>
                    <div className="col-span-2">
                        <label className="font-bold" htmlFor="eks_form_name">From Date</label>
                        <input type="date" id="fdate" name="fdate" value={element.fdate || ""} onChange={e => handleChange(index, e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                    </div>
                    <div className="col-span-2">
                        <label className="font-bold" htmlFor="eks_form_name">To Date</label>
                        <input type="date" id="tdate" name="tdate" value={element.tdate || ""} onChange={e => handleChange(index, e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                    </div>
                    <div className="col-span-2">
                        <label className="font-bold" htmlFor="eks_form_name">Department</label>
                        <input type="text" id="dept" name="dept" value={element.dept || ""} onChange={e => handleChange(index, e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                    </div>

                    {
                        index ?
                            <div className="col-span-2">
                                <button type="button" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => removeFormFields(index)}>Remove</button>
                            </div> : null
                    }
                </div>
            ))
        }
    }

    return (<>
        <Nav />
        <ToastContainer position='top-right' />
        <div className="p-2 md:p-6 bg-gray-100 flex items-center justify-center">
            <div className="container mx-auto">
                <div>
                    <div className="bg-[#275789] rounded-lg p-8 flex flex-col md:flex-row md:ml-auto w-full mt-2 md:mt-0 shadow-md mb-2">
                        <div className="md:w-1/4 w-full mb-2">
                            <img loading="lazy" alt="profile" src={formValues['eks_form_selfie'] ? `${UtilsJson.baseUrl}uploads/${formValues['eks_form_selfie']}` : `${UtilsJson.baseUrl}uploads/profile.jpg`} className="rounded-full w-36 h-36" ></img>
                        </div>
                        <div className="md:w-3/4 w-full">
                            <div>
                                <h2 className="text-white font-bold text-2xl tracking-wide">{formValues['eks_form_name'] || ""}  {formValues['eks_form_father_name'] || ''}</h2>
                            </div>
                            <p className="text-emerald-400 font-semibold mt-2.5">
                                {formValues['eks_form_mobile'] || ""}
                            </p>

                            <div className="h-1 w-full bg-black mt-4 rounded-full">
                                <div className={`h-1 rounded-full bg-green-500`} style={{ width: `${percentage}%` }}></div>
                            </div>
                            <div className="mt-3 text-white text-sm">
                                <span className="text-gray-400 font-semibold">Completion:</span>
                                <span className="ml-2">{percentage}%</span>
                            </div>
                            <div className="mt-3 text-white text-sm flex flex-col sm:flex-row gap-2">
                                <div className=" md:text-center" > <span className="text-gray-400 font-semibold">Personal Details </span>
                                    <span className="ml-2">{pcount}/{p.length}</span>
                                </div>
                                <div className=" md:text-center">
                                    <span className="text-gray-400 font-semibold">Educational Details </span>
                                    <span className="ml-2">{eduCount}/{ed.length}</span>
                                </div>
                                <div className=" md:text-center">
                                    <span className="text-gray-400 font-semibold">Experience Details </span>
                                    <span className="ml-2">{expCount}/{ex.length}</span>
                                </div>
                                <div className=" md:text-center">
                                    <span className="text-gray-400 font-semibold">Job Oppurtunities </span>
                                    <span className="ml-2">{jobCount}/{j.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded shadow-lg">
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-12">
                            <div className="lg:col-span-3 flex lg:flex-col bg-gray-200">
                                <div className={`text-gray-600 p-3 px-4 lg:p-8 w-1/4 md:w-full overflow-hidden ${step === 1 ? 'bg-sky-300' : ''}`} onClick={() => setStep(1)}>
                                    <p className="md:font-medium text-center text-[.7rem] md:text-md">Personal Details</p>
                                </div>
                                <div className={`text-gray-600 p-3 px-4 lg:p-8 w-1/4 md:w-full  ${step === 2 ? 'bg-sky-300' : ''}`} onClick={() => setStep(2)}>
                                    <p className="md:font-medium text-center text-[.7rem] md:text-md">Educational Details</p>
                                </div>
                                <div className={`text-gray-600 p-3 px-4 lg:p-8 w-1/4 md:w-full  ${step === 3 ? 'bg-sky-300' : ''}`} onClick={() => setStep(3)}>
                                    <p className="md:font-medium text-center text-[.7rem] md:text-md">Experience Details</p>
                                </div>
                                <div className={`text-gray-600 p-3 px-4 lg:p-8 w-1/4 md:w-full  ${step === 4 ? 'bg-sky-300' : ''}`} onClick={() => setStep(4)}>
                                    <p className="md:font-medium text-center text-[.7rem] md:text-md">Job Oppurtunities</p>
                                </div>
                            </div>
                            <div className={`lg:col-span-9 p-4 px-4 md:p-8 mb-6 ${step === 1 ? 'block' : 'hidden'}`}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_name">Full Name</label>
                                        <input type="text" name="eks_form_name" value={formValues['eks_form_name']} onChange={(e) => handlechange(e)} id="eks_form_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>

                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_father_name">Father's Name</label>
                                        <input type="text" name="eks_form_father_name" value={formValues['eks_form_father_name']} onChange={(e) => handlechange(e)} id="eks_form_father_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>

                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_dob">DOB</label>
                                        <input type="date" name="eks_form_dob" value={formValues['eks_form_dob']} onChange={(e) => handlechange(e)} id="eks_form_dob" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_gender">Gender</label>
                                        <select name="eks_form_gender" id="eks_form_gender" value={formValues['eks_form_gender']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" >
                                            <option value="" key="">Select</option>
                                            <option value="Male" key="Male">Male</option>
                                            <option value="Female" key="Female">Female</option>
                                        </select>
                                    </div>
                                    <div className="md:col-span-6">
                                        <label className="font-bold" htmlFor="eks_form_mail">Email</label>
                                        <input type="email" name="eks_form_mail" value={formValues['eks_form_mail']} onChange={(e) => handlechange(e)} id="eks_form_mail" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="email@domain.com" />
                                    </div>


                                    <div className="md:col-span-3 mt-2">
                                        <label className="font-bold" htmlFor="address">Photo</label>
                                        <ImageUploader
                                            name="photo"
                                            withPreview={true}
                                            withIcon={true}
                                            singleImage={true}
                                            onChange={Photo}
                                            imgExtension={[".jpg", ".png"]}
                                            maxFileSize={5242880}
                                        />
                                        {(formValues['eks_form_selfie']) ? <img loading="lazy" src={formValues['eks_form_selfie'] ? `${UtilsJson.baseUrl}uploads/${formValues['eks_form_selfie']}` : ''} className="h-56" alt="" /> : ''}
                                    </div>

                                    <div className="md:col-span-3 mt-2">
                                        <label className="font-bold" htmlFor="city">Adhar Photo</label>
                                        <ImageUploader
                                            name="adharPhoto"
                                            withIcon={true}
                                            withPreview={true}
                                            singleImage={true}
                                            onChange={AdharPhoto}
                                            imgExtension={[".jpg", ".png"]}
                                            maxFileSize={5242880}
                                        />
                                        {(formValues['eks_form_aadhar']) ? <img loading="lazy" src={formValues['eks_form_aadhar'] ? `${UtilsJson.baseUrl}uploads/${formValues['eks_form_aadhar']}` : ''} className="h-56" alt="" /> : ''}
                                    </div>

                                    <div className="md:col-span-6 text-right">
                                        <div className="inline-flex items-end">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => { setStep(2); FormUpdate('Personal Details'); }}>Save & Next</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={`lg:col-span-9 p-4 px-4 md:p-8 ${step === 2 ? 'block' : 'hidden'}`}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-6">
                                        <label className="font-bold" htmlFor="eks_form_qualification">Qualification</label>
                                        <select name="eks_form_qualification" id="eks_form_qualification" value={formValues['eks_form_qualification']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" >
                                            <option value="" key="">Select</option>
                                            <option value="12th Pass" key="12th Pass">12th Pass</option>
                                            <option value="12th Fail" key="12th Fail">12th Fail</option>
                                            <option value="Diploma" key="Diploma">Diploma</option>
                                            <option value="BE" key="BE">BE</option>
                                            <option value="B.Com" key="B.Com">B.Com</option>
                                            <option value="BBA" key="BBA">BBA</option>
                                        </select>
                                    </div>
                                    <div className="md:col-span-6 font-bold p-2 bg-slate-200  mt-3 mb-3"> School Information </div>
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_sch_name">School Name</label>
                                        <input type="text" name="eks_form_sch_name" id="eks_form_sch_name" value={formValues['eks_form_sch_name']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_sch_cmp_yr">School Completion Year (12th)</label>
                                        <input type="number" name="eks_form_sch_cmp_yr" id="eks_form_sch_cmp_yr" value={formValues['eks_form_sch_cmp_yr']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-6 mt-2">
                                        <label className="font-bold" htmlFor="address">School TC</label>
                                        <ImageUploader
                                            name="eks_form_sch_cert"
                                            withPreview={true}
                                            withIcon={true}
                                            singleImage={true}
                                            onChange={SchoolTC}
                                            imgExtension={[".jpg", ".png"]}
                                            maxFileSize={5242880}
                                        />
                                        {(formValues['eks_form_sch_cert']) ? <img loading="lazy" src={formValues['eks_form_sch_cert'] ? `${UtilsJson.baseUrl}uploads/${formValues['eks_form_sch_cert']}` : ''} className="h-56" alt="" /> : ''}
                                    </div>
                                    <div className="md:col-span-6 font-bold p-2 bg-slate-200 mt-3 mb-3"> College Information </div>
                                    <div className="md:col-span-6">
                                        <label className="font-bold" htmlFor="eks_form_clg_name">College Name</label>
                                        <input type="text" name="eks_form_clg_name" id="eks_form_clg_name" value={formValues['eks_form_clg_name']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_clg_cource">Course In</label>
                                        <select name="eks_form_clg_cource" id="eks_form_clg_cource" value={formValues['eks_form_clg_cource']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" >
                                            <option value="" key="">Select</option>
                                            <option value="Mechanical" key="Mechanical">Mechanical</option>
                                            <option value="EEE" key="EEE">EEE</option>
                                            <option value="Civil" key="Civil">Civil</option>
                                        </select>
                                    </div>
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_clg_cmp_yr">College Completion Year</label>
                                        <input type="month" name="eks_form_clg_cmp_yr" id="eks_form_clg_cmp_yr" value={formValues['eks_form_clg_cmp_yr']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-6 mt-2">
                                        <label className="font-bold" htmlFor="eks_form_clg_cert">Course completion certificate / TC / Final Mark Sheet</label>
                                        <ImageUploader
                                            name="eks_form_clg_cert"
                                            withIcon={true}
                                            withPreview={true}
                                            singleImage={true}
                                            onChange={CollegeTC}
                                            imgExtension={[".jpg", ".png"]}
                                            maxFileSize={5242880}
                                        />
                                        {(formValues['eks_form_clg_cert']) ? <img loading="lazy" src={formValues['eks_form_clg_cert'] ? `${UtilsJson.baseUrl}uploads/${formValues['eks_form_clg_cert']}` : ''} className="h-56" alt="" /> : ''}
                                    </div>

                                    <div className="md:col-span-6 text-right">
                                        <div className="inline-flex items-end">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => { setStep(3); FormUpdate('Educational Details'); }}>Save & Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`lg:col-span-9 p-4 px-4 md:p-8  ${step === 3 ? 'block' : 'hidden'}`}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-6">
                                        <label className="font-bold" htmlFor="eks_form_exp">Experience</label>
                                        <select name="eks_form_exp" id="eks_form_exp" value={formValues['eks_form_exp']} onChange={(e) => handlechange(e)} className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" >
                                            <option value="" key="">Select</option>
                                            <option value="Fresher" >Fresher</option>
                                            <option value="0 - 1 Year" >0 - 1 Year</option>
                                            <option value="1 - 2 Years" >1 - 2 Years</option>
                                            <option value="Above 2 Year" >Above 2 Year</option>
                                        </select>
                                    </div>
                                    <div className="md:col-span-6 mt-2 ">
                                        <label className="font-bold" htmlFor="address">Experience Details</label>
                                        {typeof (formValues) == 'object' ? addForms() : ''}

                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2" type="button" onClick={() => addFormFields()}>Add</button>
                                    </div>
                                    <div className="md:col-span-2">
                                        <label className="font-bold" htmlFor="eks_form_sup_name">Superior Name</label>
                                        <input type="text" name="eks_form_sup_name" value={formValues['eks_form_sup_name']} onChange={(e) => handlechange(e)} id="eks_form_sup_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-2">
                                        <label className="font-bold" htmlFor="eks_form_sup_no">Superior Phone Number</label>
                                        <input type="number" name="eks_form_sup_no" value={formValues['eks_form_sup_no']} onChange={(e) => handlechange(e)} id="eks_form_sup_no" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-2">
                                        <label className="font-bold" htmlFor="eks_form_last_salary">Last Drawn Salary (Monthly)</label>
                                        <input type="number" name="eks_form_last_salary" value={formValues['eks_form_last_salary']} onChange={(e) => handlechange(e)} id="eks_form_last_salary" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-6 mt-2">
                                        <label className="font-bold" htmlFor="address">Experience certificate</label>
                                        <ImageUploader
                                            name="eks_form_exp_cert"
                                            withPreview={true}
                                            withIcon={true}
                                            singleImage={true}
                                            onChange={ExperienceCertificate}
                                            imgExtension={[".jpg", ".png"]}
                                            maxFileSize={5242880}
                                        />
                                        {(formValues['eks_form_exp_cert']) ? <img loading="lazy" src={formValues['eks_form_exp_cert'] ? `${UtilsJson.baseUrl}uploads/${formValues['eks_form_exp_cert']}` : ''} className="h-56" alt="" /> : ''}
                                    </div>
                                    <div className="md:col-span-6 mt-2">
                                        <label className="font-bold" htmlFor="eks_form_resume_cert">Resume</label>
                                        <ImageUploader
                                            name="eks_form_resume_cert"
                                            withPreview={true}
                                            withIcon={true}
                                            singleImage={true}
                                            label="Max file size: 5mb, accepted: jpg|png|pdf"
                                            buttonText="Choose Images / PDF"
                                            onChange={ResumeCertificate}
                                            imgExtension={[".jpg", ".png", ".pdf", ".doc"]}
                                            maxFileSize={5242880}
                                        />
                                    </div>
                                    <div className="md:col-span-6 text-right">
                                        <div className="inline-flex items-end">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => { setStep(4); FormUpdate('Experience Details'); }}>Save & Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`lg:col-span-9 p-4 px-4 md:p-8 ${step === 4 ? 'block' : 'hidden'}`}>
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-6">
                                        <label className=" font-bold mb-2" htmlFor="eks_form_name">Tick if Yes</label>
                                        <div className="flex items-start mt-2">
                                            <div className="flex items-center h-5">
                                                <input id="eks_form_stay" name="eks_form_stay" value='1' checked={formValues['eks_form_stay']?.indexOf((1).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox"
                                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label>Willing to stay in room ?</label>
                                            </div>
                                        </div>
                                        <div className="flex items-start mt-2">
                                            <div className="flex items-center h-5">
                                                <input id="eks_form_stay" name="eks_form_stay" value='2' checked={formValues['eks_form_stay']?.indexOf((2).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms"
                                                    type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label>Have two Wheeler ? </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="md:col-span-6">
                                        <label className=" font-bold mb-2" htmlFor="eks_form_name">Location Preffered</label>
                                        <div className="grid grid-cols-8">
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'1'} checked={formValues['eks_form_location']?.indexOf((1).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Chennai</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'2'} checked={formValues['eks_form_location']?.indexOf((2).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Oragadam</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'3'} checked={formValues['eks_form_location']?.indexOf((3).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Padi</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'4'} checked={formValues['eks_form_location']?.indexOf((4).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Vallam </label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'5'} checked={formValues['eks_form_location']?.indexOf((5).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Sriperumbudur </label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'6'} checked={formValues['eks_form_location']?.indexOf((6).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Chengalpet</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'7'} checked={formValues['eks_form_location']?.indexOf((7).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Marai Malai Nagar</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'8'} checked={formValues['eks_form_location']?.indexOf((8).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Coimbatore</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'9'} checked={formValues['eks_form_location']?.indexOf((9).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Trichy</label>
                                                </div>
                                            </div>
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_location" name="eks_form_location" value={'A'} checked={formValues['eks_form_location']?.indexOf(('A').toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Any Location in TamilNadu</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:col-span-6">
                                        <label className=" font-bold mb-2" htmlFor="eks_form_name">Job Type</label>
                                        <div className="grid grid-cols-8">
                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_process" name="eks_form_process" value={'1'} checked={formValues['eks_form_process']?.indexOf((1).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Production </label>
                                                </div>
                                            </div>

                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_process" name="eks_form_process" value={'2'} checked={formValues['eks_form_process']?.indexOf((2).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Quality </label>
                                                </div>
                                            </div>

                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_process" name="eks_form_process" value={'3'} checked={formValues['eks_form_process']?.indexOf((3).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Assembly </label>
                                                </div>
                                            </div>

                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_process" name="eks_form_process" value={'4'} checked={formValues['eks_form_process']?.indexOf((4).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Maintenance </label>
                                                </div>
                                            </div>

                                            <div className="flex items-start mt-2 md:col-span-2  col-span-8">
                                                <div className="flex items-center h-5">
                                                    <input id="eks_form_process" name="eks_form_process" value={'5'} checked={formValues['eks_form_process']?.indexOf((5).toString()) > -1 ? true : false} onChange={(e) => handlechange(e, 'checkbox')} aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label>Admin & Accounts </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:col-span-3">
                                        <label className="font-bold" htmlFor="eks_form_exp_salary">Expected Salary (Monthly)</label>
                                        <input type="number" name="eks_form_exp_salary" value={formValues['eks_form_exp_salary']} onChange={(e) => handlechange(e)} id="eks_form_exp_salary" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>
                                    <div className="md:col-span-6 text-right">
                                        <div className="inline-flex items-end">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => { FormUpdate('Job Oppurtunities'); }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>)
}

export default Forms;